import {
  Box,
  Grid,
  Button,
  Typography
} from "@mui/material"

import { ThemeProvider } from "@mui/material/styles"
import theme from "theme"
import GitHubIcon from "@mui/icons-material/GitHub"
import EmailRoundedIcon from "@mui/icons-material/EmailRounded"
import Header from "components/Header"
import Space from "components/Space"
import ImageTicker from "components/ImageTicker"
import Badge from "components/Badge"

let stack = [
  "REACT",
  "NEXT.JS",
  "THEGRAPH",
  "SQL",
  "MONGODB",
  "GRAPHQL",
  "HASURA",
  "PYTHON",
  "SOLIDITY",
  "R",
  "ALCHEMY",
  "INFURA",
  "METAMASK",
  "RAINBOWKIT",
  "WAGMI.JS",
  "AWS",
  "VERCEL",
  "GITHUB"
]

const HomePage = () => {
  return (
    <Space mt={1} ml={2} mr={2}>
      <ThemeProvider theme={theme}>
      <Space bl={4} bt={4} br={4} bb={8}>
        <Header/>

        <Space pl={2} pt={2} pr={2} pb={2} bl={2}>
          <Typography variant="h4" fontFamily="Paytone One" marginBottom={2}>SERVICES</Typography>
          <Typography variant="h5" fontFamily="Space Mono">
            <Typography fontFamily="inherit" fontSize="inherit">- Distributed Web Technologies</Typography>
            <Typography fontFamily="inherit" fontSize="inherit">- Data Collection and Analytics</Typography>
            <Typography fontFamily="inherit" fontSize="inherit">- Cloud Infrastructure and Computing</Typography>
            <Typography fontFamily="inherit" fontSize="inherit">- API Development and Hosting</Typography>
            <Typography fontFamily="inherit" fontSize="inherit">- Contract Development and Audits</Typography>
            <Typography fontFamily="inherit" fontSize="inherit">- Wallet Integrations</Typography>
            <Typography fontFamily="inherit" fontSize="inherit">- Metadata Standards [721/1155/2981]</Typography>
            <Typography fontFamily="inherit" fontSize="inherit">- On-Chain Data Storage [L1/L2]</Typography>
            <Typography fontFamily="inherit" fontSize="inherit">- Decentralized Content Managment via IPFS</Typography>
            <Typography fontFamily="inherit" fontSize="inherit">- Custom Solutions</Typography>
          </Typography>
        </Space>
      </Space>
      
      <Grid container border="4px solid #000010">
        <Grid item xs={12} sm={8}>
          <Space pl={2} pt={2} pr={2} pb={2} bl={4} bt={4} br={4}>
            <Typography variant="h4" fontFamily="Paytone One" marginBottom={2}>STACK</Typography>
            <Box display="flex-inline">
              {stack.map((x) => <Badge key={x} label={x}></Badge>)}
            </Box>
          </Space>
        </Grid>
        
        <Grid item xs={12} sm={4} sx={{backgroundColor: "#000010" }}>
          <Space pl={2} pt={2} pr={2} pb={2} bt={4}>
            <Typography variant="h4" fontFamily="Paytone One" marginBottom={2} color="#E0D3D3">LINKS</Typography>
            <Box marginLeft={-2} sx={{display: "inline-block"}}>
              <Button
                sx={{color: "#E0D3D3", "&:hover": {color: "#9C8F8F"} }}
                onClick={() => {
                  window.open("https://github.com/latent-laboratory")
                }}
                >
                <GitHubIcon fontSize="large"/>
              </Button>
            </Box>
            <Box marginLeft={-2} sx={{display: "inline-block"}}>
              <Button
                sx={{color: "#E0D3D3", "&:hover": {color: "#9C8F8F"} }}
                onClick={() => {
                  window.open("mailto:contact@latentlabs.xyz")
                }}
                >
                <EmailRoundedIcon fontSize="large"/>
              </Button>
            </Box>
          </Space>        
        </Grid>
      </Grid>

      <Space bl={6.5} br={4} bb={8}>
        <Space pl={2} pt={2} pr={2} pb={2} bl={2}>
          <Typography variant="h4" fontFamily="Paytone One" marginBottom={2}>CONTACT</Typography>
          <Typography 
            sx={{
              fontFamily: "Space Mono",
              fontSize: 20,
              maxWidth: 400
            }}>
            For general or business inquiries please reach out to{" "}
            <a href="mailto:contact@latentlabs.xyz">
              contact@latentlabs.xyz
            </a>
          </Typography>
        </Space>
      </Space>

      <Box display="flex" paddingRight={2} justifyContent="right" sx={{backgroundColor: "#000010"}}>
        <Typography fontFamily="Space Mono" fontStyle="italic" color="#E0D3D3">{"❤ FRIENDS ❤"}</Typography>
      </Box>
      <Space bl={12} br={8}>
        <Box sx={{backgroundColor: "#000010",paddingTop: 2, paddingBottom: 2}}>
          <ImageTicker/>
        </Box>
      </Space>

      <Space>
        <Box sx={{
          backgroundColor: "#000010",
          color: "#E0D3D3",
          display: "grid",
          paddingTop: 5,
          paddingBottom: 5,
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Space Mono", 
          fontSize: 15
        }}>
          <Box sx={{ display: "flex", justifyContent: "center"}}> 
            <img src="/media/500x500-LL-XYZ.png" alt="" height="75px"></img>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center"}}> 
            <Typography fontFamily="inherit" fontSize="inherit">
              &copy;2024 by Latent Labs LLC
            </Typography>
          </Box>
          <Box sx={{display: "flex", justifyContent: "center"}}> 
            <Typography fontFamily="inherit" fontSize="inherit">
              All Rights Reserved
            </Typography>
          </Box>
        </Box>
      </Space>
      </ThemeProvider>
    </Space>
  )
}

export default HomePage
